/* eslint-disable react/no-access-state-in-setstate,react/destructuring-assignment,no-plusplus */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';
import styled, { css } from 'styled-components';
import { Trans } from '@lingui/react';
import { getCurrencyCodeString } from 'features/utils/currency';
import { resolveInCodebase, resolveThemeValue } from '../../design/utils';
import tokens from '../../design/tokens/tokens';

export interface TextInputProps {
  name: string;
  value?: any;
  id: string;
  label?: any;
  hasError?: any;
  errorMessage?: string | null;
  refs?: any;
  type?: string;
  onChange?: any;
  showError?: boolean;
  showSwicthTypeIcon?: boolean;
  correctMessage?: any;
  maxLength?: number;
  pattern?: string;
  active?: boolean;
  textAreaRowsNumber?: number;
  rightLabelElement?: any;
  showCurrency?: boolean | 'auto';
  settings?: { currency: string };
  onFocus?: any;
  onBlur?: any;
  showPass?: any;
  className?: string;
  readOnly?: boolean;
  disabled?: boolean;
  showSymbol?: boolean | 'auto';
  symbolToShow?: string;
  showLocationIcon?: boolean;
  getMyLocationCallback?: any;
  min?: string;
  max?: string;
  addOptionalLabel?: boolean;
  textAlign?: 'left' | 'right';
  autoComplete?: string;
  inputMode?: string;
  forcedCurrency?: string;
  step?: number;
  required?: boolean;
}

export default class TextInput extends React.PureComponent<TextInputProps> {
  constructor(props) {
    super(props);
    if (!props.id && !props.name) {
      throw new Error('expected id but none present');
    }

    this.state = {
      active: (props.value && String(props.value).length > 0) || props.type === 'date' || props.active === true,
      showPass: false,
    } as any;

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.currencyLabelRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.active && this.props.value && prevProps.value !== this.props.value) ||
      this.props.type === 'date' ||
      this.props.active === true ||
      this.props.value === 0
    ) {
      this.setState({ active: true });
    }
  }

  onFocus(event) {
    this.setState({ active: true });
    if (this.props.onFocus) {
      this.props.onFocus(event);
    }
  }

  onBlur(event) {
    this.setState({ active: event.target.value.length !== 0 });
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  }

  showPassword() {
    this.setState({ showPass: !this.state.showPass });
  }

  render() {
    const {
      id,
      label = '',
      type,
      refs,
      className,
      hasError,
      errorMessage,
      showError = true,
      showSwicthTypeIcon = false,
      maxLength,
      pattern,
      textAreaRowsNumber,
      rightLabelElement,
      settings = null,
      showCurrency = false,
      readOnly = false,
      disabled = false,
      symbolToShow = '',
      showSymbol = false,
      showLocationIcon = false,
      getMyLocationCallback = null,
      addOptionalLabel = false,
      textAlign = 'right',
      correctMessage = null,
      required,
      forcedCurrency = undefined,
      ...otherProps
    } = this.props;

    let { active }: any = this.state;
    const { showPass }: any = this.state;

    if (otherProps.active) {
      active = true;
    }

    const onTextAreaChange = () => {
      const textarea = document.getElementById('textarea');
      const limitRows = 50;
      let messageLastScrollHeight = textarea.scrollHeight;

      textarea.oninput = function () {
        let rows = parseInt(textarea.getAttribute('rows'), 10);
        // If we don't decrease the amount of rows, the scrollHeight would show the scrollHeight for all the rows
        // even if there is no text.
        textarea.setAttribute('rows', '1');

        if (rows < limitRows && textarea.scrollHeight > messageLastScrollHeight) {
          rows++;
        } else if (rows > 1 && textarea.scrollHeight < messageLastScrollHeight) {
          rows--;
        }

        if (rows === 1) rows++;

        messageLastScrollHeight = textarea.scrollHeight;
        textarea.setAttribute('rows', String(rows));
      };
    };

    return (
      <>
        <FloatingLabelInput className={type === 'textarea' ? 'textarea' : ''}>
          <FloatingLabelInputContainer className={`${hasError ? 'error' : ''} ${disabled ? 'disabled' : ''}`}>
            <FloatingLabel
              required={required}
              className={`${hasError ? 'error' : ''} ${className}`}
              htmlFor={id}
              active={active}
              currencyLabelWidth={this.currencyLabelRef.current?.offsetWidth ?? null}
            >
              {label}
              {addOptionalLabel ? (
                <span className="optional">
                  {' '}
                  (<Trans id="checkbox.label.notReqired" />)
                </span>
              ) : (
                ''
              )}
            </FloatingLabel>

            {rightLabelElement && (
              <RightFloatLabel className={className} htmlFor={id} active={active}>
                {rightLabelElement}
              </RightFloatLabel>
            )}

            {showSwicthTypeIcon && (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <span
                onClick={() => {
                  this.showPassword();
                }}
                className={`icon ${showPass ? 'icon-hide-password' : 'icon-show-password'}`}
              />
            )}
            {showLocationIcon && (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <span
                onClick={() => {
                  if (getMyLocationCallback) getMyLocationCallback();
                }}
                className="icon icon-my-location"
              />
            )}
            {type !== 'textarea' ? (
              <>
                <FloatingInput
                  data-testid={`react-input-${this.props.disabled ? 'disabled-' : ''}${
                    this.props.name || this.props.id
                  }`}
                  readOnly={readOnly}
                  disabled={disabled}
                  active={active}
                  maxLength={maxLength}
                  showKms
                  pattern={pattern}
                  step={this.props.step}
                  className={`${showLocationIcon ? 'showLocationIcon' : ''}  ${showSymbol ? 'showKms' : ''} ${
                    showCurrency ? 'showCurrency' : ''
                  } ${className} ${hasError ? 'error' : ''}`}
                  id={id}
                  onBlur={this.onBlur}
                  onFocus={this.onFocus}
                  onWheel={(e) => e.target.blur()}
                  ref={refs}
                  currencyLabelWidth={this.currencyLabelRef.current?.offsetWidth ?? null}
                  textAlign={textAlign}
                  type={showPass && showSwicthTypeIcon ? 'text' : type}
                  {...otherProps}
                />
                {(showCurrency === true || (showCurrency === 'auto' && (active || this.props.value?.length > 0))) && (
                  <Currency ref={this.currencyLabelRef}>{forcedCurrency || getCurrencyCodeString(settings)}</Currency>
                )}
                {(showSymbol === true || (showSymbol === 'auto' && (active || this.props.value?.length > 0))) && (
                  <Currency ref={this.currencyLabelRef}>{symbolToShow}</Currency>
                )}
              </>
            ) : (
              <FloatingTextArea
                active={active}
                disabled={disabled}
                readOnly={readOnly}
                className={className}
                id="textarea"
                onBlur={this.onBlur}
                onFocus={this.onFocus}
                onChange={onTextAreaChange}
                w
                ref={refs}
                type={type}
                rows={textAreaRowsNumber || 2}
                {...otherProps}
              />
            )}
          </FloatingLabelInputContainer>
        </FloatingLabelInput>
        {hasError && showError && <ErrorWrapper>{hasError?.message ? hasError.message : errorMessage}</ErrorWrapper>}
        {correctMessage && <CorrectWrapper>{correctMessage}</CorrectWrapper>}
      </>
    );
  }
}

const FloatingLabelInput = styled.div`
  width: 100%;
  border-radius: 8px;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    background-color: red !important;
  }

  &.textarea {
    textarea {
      resize: vertical;
    }
    > div {
      /* min-height: 90px; */
      /* height: fit-content; */
      height: 100%;
    }
    label {
      bottom: auto;
      top: 14px;
      white-space: nowrap;
    }
  }
`;

const Currency = styled.div`
  pointer-events: none;
  position: absolute;
  right: 12px;
  bottom: 6px;
  line-height: 1;
  color: ${resolveThemeValue(tokens.color.text.textPrimary, '#1f2244')};
  font-size: 16px;
  padding-left: 10px;
  /* LW */

  ${resolveInCodebase(
    'fwc',
    css`
      bottom: 11px;
    `
  )}
`;

const FloatingLabelInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  height: ${({ theme }) => (theme.codebase === 'fwc' ? '52px' : '48px')};
  border-radius: 8px;
  border: solid 1px ${resolveThemeValue(tokens.color.border.border, 'var(--color-beige)')};

  background-color: white;

  &:hover {
    border: solid 1px ${resolveThemeValue(tokens.color.border.borderHover, 'var(--color-dark-grey)')};
  }

  ${resolveInCodebase(
    'fwc',
    css`
      padding: 1px;
      &:has(> input:focus) {
        padding: 0;
        border: solid 2px ${resolveThemeValue(tokens.color.border.borderHover, 'var(--color-dark-grey)')};
      }
    `
  )}

  &.error {
    border: solid 1px ${resolveThemeValue(tokens.color.text.textError, '#fd253f')};
    color: ${resolveThemeValue(tokens.color.text.textError, '#fd253f')};
    & > div {
      color: ${resolveThemeValue(tokens.color.text.textError, '#fd253f')};
    }
  }
  .icon {
    font-size: 1.3rem;
    position: absolute;
    right: 12px;
    top: 14px;
    cursor: pointer;
  }

  &.disabled {
    background-color: ${resolveThemeValue(tokens.color.backgrounds.disabled, '#dad1d1')};
    &:hover {
      border-color: ${resolveThemeValue(tokens.color.border.border, '#dad1d1')};
    }
  }
`;

const FloatingInput = styled.input`
  padding: 0;
  padding-left: 16px;
  padding-bottom: 2px;
  border-radius: 8px;
  padding-top: 10px;
  margin: 0;
  border: none;
  outline: none;
  font-size: 16px;
  &::placeholder {
    color: #9b9b9b;
    opacity: ${(props) => (props.active ? 1 : 0)};
    transition: opacity 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }
  &:disabled {
    background: transparent;
    color: #8e7e7f !important;
    opacity: 1 !important;
    -webkit-text-fill-color: #8e7e7f; /* Override iOS / Android font color change */
    -webkit-opacity: 1;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &.showCurrency {
    padding-right: ${(props) => (props.currencyLabelWidth ? `${props.currencyLabelWidth + 20}px` : '36px')};
    text-align: ${(props) => (props.textAlign === 'right' ? 'right' : 'left')};

    
    ${resolveInCodebase(
      'fwc',
      css`
        text-align: left;
      `
    )}
    

  ${resolveInCodebase(
    'fwc',
    css`
      height: 100%;
      padding: ${resolveThemeValue(tokens.spacing.spacingS)} ${resolveThemeValue(tokens.spacing.spacingL)};
      margin-top: 20px;
    `
  )}

  &::-webkit-contacts-auto-fill-button,
  &::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    position: absolute;
    right: 0;
    display: none;
  }

  &.showKms {
    padding-right: ${(props) => (props.currencyLabelWidth ? `${props.currencyLabelWidth + 20}px` : '46px')};
    text-align: ${(props) => (props.textAlign === 'right' ? 'right' : 'left')};
  }

  &.showLocationIcon {
    padding-right: 46px;
  }

  &.error {
    color: '#fd253f';
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  :[type=number] {
    -moz-appearance: textfield;
  }
`;

const FloatingTextArea = styled.textarea`
  padding: 0;
  padding-left: 16px;

  padding-bottom: 5px;
  border-radius: 8px;
  padding-top: 15px;

  height: 100%;
  resize: none;
  margin: 0;
  border: none;
  outline: none;
  font-size: 16px;
  &::placeholder {
    color: #9b9b9b;
    opacity: ${(props) => (props.active ? 1 : 0)};
    transition: opacity 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }
  &:disabled {
    color: #8e7e7f;
  }
`;

const FloatingLabel = styled.label`
  padding: 0;
  margin: 0;
  border: 0;
  position: absolute;
  bottom: 16px;
  left: 16px;
  transition: all 0.2s ease-in-out;
  transform-origin: left top;
  font-size: 16px;
  cursor: text;
  pointer-events: none;
  transform: ${(props) => (props.active ? 'translate3d(0, -65%, 0) scale(0.75)' : 'none')};

  ${resolveInCodebase(
    'fwc',
    css`
      transform: ${(props) => (props.active ? 'translate3d(0, -75%, 0) scale(0.75)' : 'none')};
      bottom: 16px;
    `
  )}

  width: ${(props) =>
    props.active ? '120%' : props.currencyLabelWidth ? `calc(90% - ${props.currencyLabelWidth}px)` : '90%'};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: ${resolveThemeValue(tokens.color.text.textPlaceholder, 'var(--color-dark-grey)')};

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  span.optional {
    text-transform: lowercase;
  }

  ${({ required }) =>
    required &&
    css`
      &:after {
        content: '*';
      }
    `}

  &.error {
    color: ${resolveThemeValue(tokens.color.text.textError, '#fd253f')};
  }
`;

export const RightFloatLabel = styled.label`
  padding: 0;
  margin: 0;
  border: 0;
  position: absolute;
  bottom: 14px;
  right: 8px;
  transition: all 0.2s ease-in-out;
  transform-origin: left top;
  font-size: 12px !important;
  cursor: text;
  pointer-events: none;
  width: 100%;
  // transform: ${(props) => (props.active ? 'translate3d(0, -70%, 0) scale(1)' : 'none')};
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: var(--color-dark-grey);
  text-align: right;
`;

const ErrorWrapper = styled.div`
  font-size: 12px;
  color: ${resolveThemeValue(tokens.color.text.textError, '#fd253f')};
  margin-top: 8px;
`;

const CorrectWrapper = styled.div`
  font-size: 12px;
  margin-top: 8px;
`;
